<template>
      <v-card class="mt-4 mx-2 cardStyle" v-if="job !== undefined">
        <v-card-text>
         <div class="text-h6">
          <v-chip label class="my-2">{{ job.assignedAgents.length }}</v-chip>
          Assigned Agent(s)
          </div>
         <div style="max-height: 30vh; overflow-y: scroll;">
           <div v-for="(agent, i) in job.assignedAgents" :key="i">
            <v-card class="d-flex" flat>
              <v-card class="mr-auto d-flex text-h6" flat>
                <v-card flat>
                  <v-avatar color="blue lighten-1" size="40">{{ agent.agent_code }}</v-avatar>
                </v-card>
                <div class="mt-1 mx-2">{{ agentName(agent) }}</div>
              </v-card>
              <v-card class="py-2 d-flex justify-end" flat>
                  <v-chip label :color="agent.status === 'Active' ? 'green lighten-1' : 'blue lighten-1'" >{{ agent.status }}</v-chip>
              </v-card>
            </v-card>
            <v-divider></v-divider>
          </div>
         </div>
          <div class="text-h6"> Reassign Agent </div>
          <v-form class="mt-2" v-model="isValid" ref="imageForm">
            <v-select
              clearable
              filled
              chips
              label="Select Agent"
              :items="agents"
              item-text="name"
              item-value="code"
              v-model="reassignedFormData.agent_code"
              :rules="rules"
            >
              <template v-slot:item="{ item, on, attrs }">
                <v-list-item ripple v-on="on" v-bind="attrs">
                  <v-list-item-avatar
                    color="blue lighten-4"
                    style="color: rgb(0, 68, 139)"
                  >
                    {{ item.code }}
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span>{{ item.name }}</span>
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
                    <v-list-item-subtitle>{{ item.phone_no }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            class="btnActions"
            block
            @click="reassignJobCard(job)"
          >
            Reassign
          </v-btn>
        </v-card-actions>
      </v-card>
</template>
<style>
  @import url('../styles.css');
</style>
<script>
import RepoMethods from '../RepoMethods'
export default {
  name: 'Reassign',
  data: function () {
    return {
      isValid: false,
      reassigned: '',
      rules: [
        v => !!v || 'Field is required'
      ],
      reassignedFormData: {
        agent_code: ''
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next((v) => {
      const { jobCode } = v.$route.params
      v.$store.dispatch('reposession/updatePrevRoute', from)
      v.getJob(atob(jobCode))
    })
  },
  computed: {
    agents: function () {
      return this.$store.getters['reposession/getAgents']({ job: this.job, coordinator: this.$store.getters['auth/user'] })
    },
    job: function () {
      return this.$store.getters['reposession/getJobObject']()
    }
  },
  methods: {
    ...RepoMethods
  }
}
</script>
